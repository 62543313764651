import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/LegalDocs.css";
import logo from "../Assets/logo_black.png"; // Corrige la ruta de importación


function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">
      <img 
              src={logo} 
              alt="Family Insurance Logo" 
              className="ft-logo" 
              style={{ width: '233px', height: 'auto', verticalAlign: 'middle' }} 
            /> 
      </h1>

      <div className="legal-text-content">
  

        <p className="legal-title">Privacy Policy</p>
        <p className="legal-description" style={{ textAlign: 'justify' }}>
        Last update: Dec 25th, 2024
        <br /><br />
        <strong>Information Collection: </strong>

        At Family Insurance Enterprise, we value your privacy and are committed to protecting your personal information. We want to assure you that our website does not
        collect any personal data from our visitors. Our primary purpose is to provide you with comprehensive information about our services, ensuring that you have all
          the necessary details to make informed decisions.
          <br /><br />
        We strictly limit our website to providing links to our social networks, contact phone number, email address, and the physical address of our
        office. This information is provided solely for your convenience and to facilitate communication with us. We do not use any tracking technologies, such as cookies
          or web beacons, to gather information about your browsing habits or preferences.
          <br /><br />

          <strong>Use of Information: </strong>

        The information provided on our website is intended to inform and assist you regarding our services. We do not share, sell, or distribute any data about your
        visits to our site, as we do not collect any personal information to begin with. Our goal is to ensure that you feel secure while navigating our website, knowing
          that your privacy is fully respected.
          <br /><br />

        <strong>Contact Information: </strong>

        If you have any questions or concerns about our privacy practices, please do not hesitate to contact us via the provided phone number, email address. We are 
        here to ensure your experience with our services is transparent, secure, and trustworthy.
        <br /><br />
        By using our website, you acknowledge that you have read and understood our privacy policy. We reserve the right to update this policy as needed to reflect 
        changes in our practices or for other operational, legal, or regulatory reasons.
        <br /><br />
        <strong>Messaging Privacy Policy: </strong>
        <br /><br />
        <strong>*Consent: </strong>
        Consent to receive promotional and non-promotional text messages from Family Insurance Enterprise LLC may be obtained verbally during interactions with our agents. 
        By providing your explicit verbal consent, you authorize Family Insurance Enterprise LLC to transmit text messages to your mobile device, encompassing a range of 
        communications related to our service, payment reminders, and promotional messages.
        <br /><br />
        Such messages may include, but are not limited to, updates on our service, critical reminders regarding payment, and exclusive promotional offers designed to enhance 
        your experience with our company. These communications are intended to ensure you remain informed and engaged with our service, thereby facilitating a seamless and efficient client experience.
        <br /><br />
        It is important to note that message frequency may vary. Standard messaging and data rates as stipulated by your mobile carrier may apply to all communications received.
        <br /><br />
        We are committed to maintaining the highest standards of customer satisfaction and ensuring that all communications are relevant, timely, and beneficial to you. Your trust 
        is of utmost importance to us, and we are dedicated to providing a transparent and supportive service experience.
        <br /><br />
        <strong>Mobile information will not be shared with any third parties/affiliates for marketing/promotional purposes. All information collected and consent given by the client will remain 
        completely confidential and will not be shared with any third parties</strong>.
        <br /><br />
        <strong>*Opt-out instructions: </strong> 
        If you would like to be removed from receiving future messages, you may opt out of receiving text messages at any time. To do so, text STOP to our number. By opting out of 
        receiving promotional and non-promotional messages from our organization, you will no longer receive them. If you have any questions or need further assistance, please do not hesitate to contact us.


        </p>

        
        
      </div>

      <div className="legal-footer">
        <p>© 2024 Family Insurance Enterprise LLC. All rights reserved.</p>
      </div>
    </div>
  );
}

export default LegalDocs;
