import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/LegalDocs.css";
import logo from "../Assets/logo_black.png"; // Corrige la ruta de importación


function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">
      <img 
              src={logo} 
              alt="Family Insurance Logo" 
              className="ft-logo" 
              style={{ width: '166px', height: 'auto', verticalAlign: 'middle' }} 
            /> 
      </h1>

      <div className="legal-text-content">
       



        <p className="legal-title">Terms and Conditions</p>
        <p className="legal-description" style={{ textAlign: 'justify' }}>
        Last update: Dec 25th, 2024
        <br /><br />
        <strong>Site Use: </strong> Our website is intended solely to provide comprehensive information about our health insurance services. No registration or collection of personal
         information is required to browse the site. By accessing and using our site, you agree to comply with these terms and conditions. Unauthorized use of the 
         website or misuse of any information posted on the site is strictly prohibited.
         <br /><br />
         <strong>Intellectual Property: </strong> All content on our site, including but not limited to text, images, graphics, logos, and software, is the property of Family Insurance Enterprise
        LLC and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works based on any of the content on our site without our express written permission. Any unauthorized use of our intellectual property may result in legal action.
        <br /><br />
        <strong>Limitation of Liability: </strong> We strive to provide accurate and up-to-date information on our website; however, we make no guarantees regarding the completeness, accuracy,
        or reliability of the information provided. We are not responsible for any damages, whether direct, indirect, incidental, or consequential, arising from the use of our
          website or the information provided on it. This includes, but is not limited to, damages caused by errors, omissions, or interruptions in the availability of the site.
          <br /><br />
          <strong>Modifications: </strong> We reserve the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting on our site. It is your
        responsibility to review these terms periodically to stay informed of any updates. Continued use of the site after modifications constitutes acceptance of the revised
          terms.
          <br /><br />
          <strong>Governing Law: </strong>These terms and conditions are governed by the laws of the State of Texas, without regard to its conflict of law provisions. By using our website, you
        agree that any legal action or proceeding related to your use of the site will be brought exclusively in the courts located in the State of Texas.
        <br /><br />
        <strong>Entire Agreement: </strong> These terms and conditions constitute the entire agreement between you and Family Insurance Enterprise LLC regarding the use of our website and supersede
        any prior agreements or understandings, whether written or oral, related to such use.
        <br /><br />
        <strong>Contact Information: </strong> If you have any questions or concerns regarding these terms and conditions, please contact us through the provided phone number, email address.
        We are here to ensure your experience with our services is transparent, secure, and satisfactory.
        <br /><br />
        <strong>Messaging Terms & Conditions: </strong> Consent to receive promotional and non-promotional text messages may be obtained verbally during interactions with our agents. By verbally agreeing, you authorize 
        Family Insurance Enterprise LLC to send you text messages about our service, payment reminders, and promotional offers. The frequency of messages may vary. Please note that standard message and data rates may apply 
        as per your mobile carrier's terms.
        <br /><br />
        For help please text HELP or if you need assistance or inquiries, you can reach us at familyinsurancecontact@gmail.com. Should you wish to stop receiving these communications at any time, simply text the word STOP to our number.
        <br /><br />
        <strong>*</strong>Copy of the OPT-in script that is read to the client to accept receiving messages from Family Insurance Enterprise LLC:
        <br /><br />
        Agent: "Do you agree to receive text messages from us to provide you with information about our service, payment reminders, and/or promotions on your phone number (xxx) xxx-xxxx? Messages may include relevant information, and may be used 
        for promotional purposes. Standard message and data rates will apply based on your carrier. You may unsubscribe at any time by texting the word STOP. Do you agree?"
        <br /><br />
        Client: "Yes, I agree."
        <br /><br />
        We value your privacy and are committed to providing you with relevant and beneficial information.
        </p>

        <p className="legal-title"></p>
      

        <p className="legal-title"></p>
        
      </div>

      <div className="legal-footer">
        <p>© 2024 Family Insurance Enterprise LLC. All rights reserved.</p>
      </div>
    </div>
  );
}

export default LegalDocs;
